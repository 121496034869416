import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import BusinessCreateQRView from "./business-create-qr-view";
import DashboardTemplate from "../../../template/dashboard";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_LOGS, COMPANY_INFO } from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import moment from "moment";
import { Form, Spin } from "antd";
import { CREATE_WESCAN_USER } from "../../../graphql/muattions";
import { GENERATE_OTP, SEND_CODE } from "../../../helpers/services";
import randomize from "randomatic";

const BusinessCreateQRController = () => {
	const [form] = Form.useForm();
	const [spinning, setSpinning] = useState(false);
	const [success, setSuccess] = useState(false);
	const [createWeScanUser] = useMutation(CREATE_WESCAN_USER, {
		onCompleted(res) {
			sendCode(res.insert_wescan_users.returning[0]?.mobile_number);
		},
	});

	const _onFinishHandler = (data) => {
		setSuccess(false);
		data = {
			...data,
			[types.MOBILE_NUMBER]: `63${data[types.MOBILE_NUMBER]}`,
			[types.CODE]: randomize("A0", 5),
		};
		setSpinning(true);
		createWeScanUser({
			variables: {
				data,
			},
		});
	};

	const sendCode = async (mobile_number) => {
		await SEND_CODE(mobile_number);
		setSpinning(false);
		setSuccess(true);
		form.resetFields();
	};

	const values = { _onFinishHandler, form, success };

	return (
		<Spin spinning={spinning}>
			<GlobalContext.Provider value={values}>
				<BusinessCreateQRView />
			</GlobalContext.Provider>
		</Spin>
	);
};

export default DashboardTemplate(BusinessCreateQRController);
