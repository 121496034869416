import axios from "axios";
import {
	LOGIN_USER,
	REGISTER_COMPANY,
	CREATE_LOGIN_USER,
	GENERATE_OTP_CODE,
	SEND_VERIFICATION_CODE,
	SEND_BUSINESS_NOTIFICATIONS,
} from "../graphql/muattions";
import { print } from "graphql";

const CREATE_TOKEN = async ({ username, password }) => {
	let token;
	await axios
		.post(process.env.REACT_APP_CREATE_TOKEN_URL, {
			query: print(CREATE_LOGIN_USER),
			variables: {
				username,
				password,
			},
		})
		.then(async (res) => {
			if (res.data.data.map_login?.token) {
				token = res.data.data.map_login.token;
			}
		})
		.catch((err) => console.log(err));
	return token;
};

const LOGIN = async ({ mobile_number, password }) => {
	const token = await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(LOGIN_USER),
			variables: {
				mobile_number,
				password,
				type: "company",
			},
		})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return token;
};

const REGISTER = async ({
	mobile_number,
	company_name,
	email,
	address,
	full_name,
	password,
	perks,
}) => {
	const token = await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(REGISTER_COMPANY),
			variables: {
				mobile_number,
				company_name,
				email,
				address,
				full_name,
				password,
				perks,
			},
		})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return token;
};

const SEND_CODE = async (mobile_number) => {
	const token = await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(SEND_VERIFICATION_CODE),
			variables: {
				mobile_number,
			},
		})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return token;
};

const GENERATE_OTP = async (mobile_number) => {
	const token = await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(GENERATE_OTP_CODE),
			variables: {
				mobile_number,
				type: "user",
			},
		})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return token;
};

const SEND_BUSINESS_NOTIFICATION = async (data) => {
	const token = await axios
		.post(process.env.REACT_APP_SERVICE_URL, {
			query: print(SEND_BUSINESS_NOTIFICATIONS),
			variables: {
				object: {
					...data,
				},
			},
		})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return token;
};

export {
	LOGIN,
	REGISTER,
	CREATE_TOKEN,
	SEND_CODE,
	GENERATE_OTP,
	SEND_BUSINESS_NOTIFICATION,
};
