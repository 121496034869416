import React, { useContext } from "react";
import { Row, Col, Card, Button, Form, Input, Alert, Spin } from "antd";
import { Link } from "react-router-dom";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import * as lang from "../../helpers/lang";
import * as types from "../../helpers/constants";

const LoginView = () => {
	const { _finishHandler, error, spin, form } = useContext(GlobalContext);

	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={8} xs={0}></Col>
				<Col lg={8} xs={24} style={{ marginTop: 50 }}>
					<Spin spinning={spin}>
						<div className="space-align-block">
							<Row gutter={16}>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22} xs={22}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Link to="/">
											<img
												src={require("../../assets/wescan-square.png")}
												style={{ maxWidth: 150 }}
												alt="WeTrace App"
											/>
										</Link>
									</div>
								</Col>
							</Row>
							<h2
								style={{ textAlign: "center", marginTop: 50, color: "#2BBB94" }}
							>
								{lang.SIGN_IN}
							</h2>
							<div style={{ padding: "0 15px 0 15px" }}>
								<Row gutter={16} style={{ marginTop: 40 }}>
									<Col lg={3} sm={0} xs={0} />
									<Col lg={18} sm={24} xs={24}>
										{error && (
											<div style={{ margin: "20px 0" }}>
												<Alert
													type="error"
													description="Invalid username or password"
												/>
											</div>
										)}
										<Form
											layout="vertical"
											onFinish={_finishHandler}
											form={form}
										>
											<Form.Item
												name={types.MOBILE_NUMBER}
												label={lang.MOBILE_NUMBER}
												rules={VALIDATION.MOBILE_NUMBER}
												hasFeedback
											>
												<Input prefix="+63" type="number" size="large" />
											</Form.Item>
											<Form.Item
												name={types.PASSWORD}
												label={lang.PASSWORD}
												rules={VALIDATION.PASSWORD}
												hasFeedback
											>
												<Input.Password size="large" />
											</Form.Item>
											<Button
												style={{ backgroundColor: "#2BBB94", color: "white" }}
												htmlType="submit"
												size="large"
											>
												LOGIN
											</Button>
										</Form>
									</Col>
									<Col lg={3} sm={0} xs={0} />
								</Row>
							</div>
						</div>
					</Spin>
				</Col>
				<Col lg={8} xs={0}></Col>
			</Row>
		</div>
	);
};

export default LoginView;
