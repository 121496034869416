import React, { useContext } from "react";
import { Row, Drawer, Col, Input, Form, Select, Button, Spin } from "antd";
import { GlobalContext } from "../../../helpers/global-context";

const NotifyCustomersView = (props) => {
  const { companyInfo, loading, drawer, setDrawer, form, handleFinishForm } = useContext(GlobalContext);

  return (
    <Row gutter={16}>
      <Drawer
        title="Add Scanner"
        placement="right"
        closable={true}
        onClose={() => setDrawer(false)}
        visible={drawer}
        width={500}
      ></Drawer>
      <Col lg={16} sm={24} xs={24}>
        <h1>Notify Customers</h1>
        <div style={{ margin: "10px 0" }}>
          {loading && <Spin/>}
          {!loading && !companyInfo?.is_premium && (
            <div>
              <p>You are not subscribed to a plan.</p>
              <p>Only premium accounts can notify their visitors/customers.</p>
            </div>
          )}
           {!loading && companyInfo?.is_premium && (
            <div>
              <p>Reach your customers using our notification feature.</p>
              <Form form={form} onFinish={handleFinishForm}>
                <Form.Item name="subject" rules={[{
                  required: true,
                  message: 'Subject is required'
                }]}>
                  <Input rows={5} placeholder="Subject"/>
                </Form.Item>
                <Form.Item name="message" rules={[{
                  required: true,
                  message: 'Message is required'
                }]}>
                  <Input.TextArea rows={5} placeholder="Message"/>
                </Form.Item>
                <Form.Item name="type" rules={[{
                  required: true,
                  message: 'Notification Type is required'
                }]}>
                  <Select placeholder="Notification type">
                    <Select.Option value="sms">SMS</Select.Option>
                    <Select.Option value="email">Email</Select.Option>
                    <Select.Option value="both">SMS & Email</Select.Option>
                  </Select>
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Notify Customers
                </Button>
              </Form>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default NotifyCustomersView;
