import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import BusinessProfileView from "./business-profile-view";
import DashboardTemplate from "../../../template/dashboard";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { GET_LOGS, COMPANY_INFO } from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import moment from "moment";
import { Form, Spin } from "antd";
import { CREATE_WESCAN_USER, UPDATE_PROFILE_IMAGE } from "../../../graphql/muattions";
import { GENERATE_OTP, SEND_CODE } from "../../../helpers/services";
import randomize from "randomatic";
import S3 from "react-aws-s3";

const BusinessProfileController = () => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
    s3Url: process.env.REACT_APP_S3_URL,
  };
  const S3Client = new S3(config);
  const [spinning, setSpinning] = useState(false);
	const [image, setImage] = useState(null);
	
	const [updateProfile] = useMutation(UPDATE_PROFILE_IMAGE)
	const { data: company_data, loading } = useQuery(COMPANY_INFO, {
    variables: {
      mobile_number: localStorage.getItem(types.MOBILE_NUMBER),
    },
    fetchPolicy: "network-only",
  });

  const handleUpload = async () => {
		setSpinning(true)
    const { location: user_picture } = await S3Client.uploadFile(
      image,
      "wescanpic-" + moment().format("YYYY-MM-DD h:m:s").toString()
		);
		if (user_picture) {
			const update = await updateProfile({
				variables: {
					id: company_data?.wescan_companies?.[0]?.id,
					image: user_picture
				}
			})
			if (update?.data?.update_wescan_companies?.affected_rows) {
				setSpinning(false)
				window.location.reload()
			}
			setSpinning(false)
		}
	};
	
	const handleUserIdImageChange = async (file, fileList) => {
    setImage(file);
  };

  const values = {
		handleUpload,
		spinning,
		image,
		handleUserIdImageChange,
		company_data
	};

  return (
    <Spin spinning={spinning}>
      <GlobalContext.Provider value={values}>
        <BusinessProfileView />
      </GlobalContext.Provider>
    </Spin>
  );
};

export default DashboardTemplate(BusinessProfileController);
