import React from "react";
import { Layout, Menu, Button } from "antd";
import { Link } from "react-router-dom";

const { Header, Content } = Layout;

const BaseTemplate = (Component) => {
	return class extends React.Component {
		render() {
			return (
				<Layout>
					<Header className="header" style={{ backgroundColor: "#f9f9f9" }}>
						<Menu
							theme="light"
							mode="horizontal"
							defaultSelectedKeys={["2"]}
							style={{ backgroundColor: "#f9f9f9", float: "right" }}
						>
							<Button
								shape="round"
								size="large"
								style={{ backgroundColor: "#2BBB94", color: "white" }}
							>
								<Link to="/lgu-login">LGU Login</Link>
							</Button>
						</Menu>
					</Header>
					<Content>
						<Layout style={{ backgroundColor: "white" }}>
							<Component />
						</Layout>
					</Content>
				</Layout>
			);
		}
	};
};

export default BaseTemplate;
