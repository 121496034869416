import React, { useEffect, useState, useContext } from "react";
import { GlobalContext, AppContext } from "../../../helpers/global-context";
import NotifyCustomersView from "./notify-customers-view";
import DashboardTemplate from "../../../template/dashboard";
import { useLazyQuery, useQuery } from "@apollo/client";
import { COMPANY_INFO } from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import {Form, notification} from 'antd'
import { SEND_BUSINESS_NOTIFICATION } from "../../../helpers/services";

const NotifyCustomersController = (props) => {
  const { cookies } = useContext(AppContext);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [drawer, setDrawer] = useState(false);

  const [form] = Form.useForm()

  const { data, loading } = useQuery(COMPANY_INFO, {
    variables: {
      mobile_number: localStorage.getItem(types.MOBILE_NUMBER),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data?.wescan_companies?.length) {
      const company = data?.wescan_companies?.[0];
      setCompanyInfo(company);
    }
  }, [data]);

  const handleFinishForm = async (data) => {
    data['token'] = cookies.token
    let response = await SEND_BUSINESS_NOTIFICATION(data)
    if (response?.data?.data?.wescan_business_notification?.message) {
      notification.success({
        message: "Notifications sent",
        description: "Your message has been sent successfully!"
      })
      form.resetFields()
    }
    console.log('response', response)
  }

  const contextValues = {
    companyInfo,
    loading,
    drawer,
    setDrawer,
    handleFinishForm,
    form
  };

  return (
    <GlobalContext.Provider value={contextValues}>
      <NotifyCustomersView />
    </GlobalContext.Provider>
  );
};

export default DashboardTemplate(NotifyCustomersController);
