import React, { useContext, useEffect } from "react";
import { AppContext } from "../helpers/global-context";
import { Redirect, Route } from "react-router-dom";
import { withCookies } from "react-cookie";
import { useClearCache } from "react-clear-cache";
import { useMutation } from "@apollo/client";
import {
	UPDATE_OSLOB,
	UPDATE_ALCANTARA,
	REMOVE_APPLICATION_WITHOUT_FILES,
} from "../graphql/muattions";

const Authenticated = (props) => {
	const { cookies } = useContext(AppContext);
	const { isLatestVersion, emptyCacheStorage } = useClearCache();

	useEffect(() => {
		if (!isLatestVersion) {
			emptyCacheStorage();
			window.location.reload();
		}
	}, [isLatestVersion, emptyCacheStorage]);

	if (!isLatestVersion) {
		return <Redirect to="/logout" />;
	}

	if (props.path === "/" || props.path === "/login") {
		return cookies.token ? (
			<Redirect to="/business-dashboard" />
		) : (
			<Route {...props} />
		);
	} else {
		return cookies.token ? <Route {...props} /> : <Redirect to="/" />;
	}
};

export default withCookies(Authenticated);
