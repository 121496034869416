import React, { useContext } from "react";
import DashboardTemplate from "../../../template/dashboard";
import {
	Row,
	Col,
	Table,
	Button,
	Drawer,
	Form,
	Input,
	Modal,
	Descriptions,
} from "antd";
import { GlobalContext } from "../../../helpers/global-context";
import * as types from "../../../helpers/constants";
import * as lang from "../../../helpers/lang";
import { VALIDATION } from "../../../helpers/rules";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";
import moment from "moment";

const BusinessScanQRView = () => {
	const {
		scanners,
		visitor,
		id,
		_scanHandler,
		_onErrorHandler,
		show_temperature,
		setShowTemperature,
		temperature,
		setTemperature,
		_resetScanner,
		form,
		scanned,
		_onFinishHandler,
		_onScanHandler,
	} = useContext(GlobalContext);
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Code",
			dataIndex: "code",
			key: "code",
		},
		{
			title: "Created Date",
			dataIndex: "created_date",
			key: "created_date",
		},
		{
			title: "",
			dataIndex: "id",
			render: (val) => <Link to={`/business-scan-qr/${val}`}>USE SCANNER</Link>,
		},
	];
	return (
		<Row gutter={16}>
			<Modal
				visible={show_temperature}
				closable={true}
				onCancel={() => setShowTemperature(false)}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={_onFinishHandler}>
					<Form.Item
						name={types.TEMPERATURE}
						rules={[
							{
								required: show_temperature,
								message: "Temperature is required!",
							},
						]}
						label={lang.TEMPERATURE}
					>
						<Input size="large" />
					</Form.Item>
					<Button htmlType="submit" size="large" type="primary">
						SUBMIT
					</Button>
				</Form>
			</Modal>
			<Col lg={16} sm={24} xs={24}>
				<h1>SCAN QR CODE</h1>
				{!id && <Table columns={columns} dataSource={scanners} />}

				{id && !scanned && (
					<div style={{ marginBottom: 20 }}>
						<div style={{ width: 300, height: 300, margin: "50px 0" }}>
							<QrReader
								delay={300}
								style={{ width: "100%" }}
								onScan={_scanHandler}
								onError={_onErrorHandler}
							/>
						</div>
						<Form form={form} layout="inline" onFinish={_onScanHandler}>
							<Form.Item name="code" label="ENTER CODE">
								<Input />
							</Form.Item>
							<Button type="primary" htmlType="submit">
								SUBMIT
							</Button>
						</Form>
					</div>
				)}
				{id && scanned && (
					<div style={{ padding: "20px 0" }}>
						<Button type="primary" size="large" onClick={_resetScanner}>
							Re-Scan
						</Button>
					</div>
				)}
				{visitor && visitor?.first_name && (
					<Descriptions title="User Information" column={1}>
						<Descriptions.Item label="Name">{`${visitor?.first_name} ${visitor?.last_name}`}</Descriptions.Item>
						<Descriptions.Item label="Mobile Number">{`${visitor?.mobile_number}`}</Descriptions.Item>
						<Descriptions.Item label="Address">{`${visitor?.address}`}</Descriptions.Item>
						<Descriptions.Item label="Last Health Declaration">
							{visitor?.questionaire_answer_date
								? moment(visitor?.questionaire_answer_date).format("LL")
								: "NONE"}
						</Descriptions.Item>
						<Descriptions.Item label="Temperature">
							{visitor?.temperature}
						</Descriptions.Item>
					</Descriptions>
				)}
			</Col>
		</Row>
	);
};

export default BusinessScanQRView;
