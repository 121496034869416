import React, { useContext } from "react";
import { Row, Col, Form, Input, Button, Alert } from "antd";
import * as lang from "../../../helpers/lang";
import * as types from "../../../helpers/constants";
import { VALIDATION } from "../../../helpers/rules";
import { GlobalContext } from "../../../helpers/global-context";

const BusinessCreateQRView = () => {
	const { _onFinishHandler, form, success } = useContext(GlobalContext);

	return (
		<Row gutter={16}>
			<Col lg={24} sm={24} xs={24}>
				<h1 style={{ marginBottom: 50 }}>Pass Code Generator</h1>
				<Row gutter={16}>
					<Col span={8}>
						{success && (
							<div style={{ margin: "20px 0" }}>
								<Alert type="success" description="Registration Successful!" />
							</div>
						)}
						<Form layout="vertical" form={form} onFinish={_onFinishHandler}>
							<Form.Item
								label={lang.FIRST_NAME}
								name={types.FIRST_NAME}
								rules={VALIDATION.FIRSTNAME}
								hasFeedback
							>
								<Input />
							</Form.Item>
							<Form.Item
								label={lang.LAST_NAME}
								name={types.LAST_NAME}
								rules={VALIDATION.LASTNAME}
								hasFeedback
							>
								<Input />
							</Form.Item>
							<Form.Item
								label={lang.MOBILE_NUMBER}
								name={types.MOBILE_NUMBER}
								rules={VALIDATION.MOBILE_NUMBER}
								hasFeedback
							>
								<Input prefix="+63" />
							</Form.Item>
							<Button htmlType="submit" type="primary">
								Submit
							</Button>
						</Form>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default BusinessCreateQRView;
