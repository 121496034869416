import * as lang from "./lang";

export const VALIDATION = {
	FIRSTNAME: [{ required: true, message: "Please input your first name!" }],
	LASTNAME: [{ required: true, message: "Please input your last name!" }],
	MOBILE_NUMBER: [
		{
			required: true,
			len: 10,
			message: `Enter 10 digit mobile number!`,
		},
	],
	COMPANY_NAME: [
		{ required: true, message: "Please input your company name!" },
	],
	OFFICE_NUMBER: [
		{ required: true, message: "Please input your company office number!" },
	],
	ORIGIN_PROVINCE: [
		{ required: true, message: "Please select your province of origin" },
	],
	ORIGIN_MUNICIPALITY: [
		{ required: true, message: "Please select your municipality of origin" },
	],
	ORIGIN_BARANGAY: [
		{ required: true, message: "Please select your barangay of origin" },
	],
	ORIGIN_ADDRESS: [
		{ required: true, message: "Please input your origin address" },
	],
	DESTINATION_PROVINCE: [
		{ required: true, message: "Please select your province of destination" },
	],
	DESTINATION_MUNICIPALITY: [
		{
			required: true,
			message: "Please select your municipality of destination",
		},
	],
	DESTINATION_BARANGAY: [
		{ required: true, message: "Please select your barangay of destination" },
	],
	DESTINATION_ADDRESS: [
		{ required: true, message: "Please input your destination address" },
	],
	QR: [
		{
			required: true,
			message:
				"Please scan your QR code found in WeTrace App (WeTrace -> Device Information)",
		},
	],
	USERNAME: [{ required: true, message: "Username is required" }],
	COMPANY_NAME: [
		{ required: true, message: `${lang.COMPANY_NAME} is required!` },
	],
	FULL_NAME: [{ required: true, message: `${lang.FULL_NAME} is required!` }],
	EMAIL: [{ required: true, message: `Invalid email address!`, type: "email" }],
	ADDRESS: [{ required: true, message: `${lang.ADDRESS} is required!` }],
	PASSWORD: [{ required: true, message: `${lang.PASSWORD} is required!` }],
	CONFIRM_PASSWORD: [{ required: true, message: `Please confirm password!` }],
	PRIVACY: [
		{
			validator: (_, value) =>
				value
					? Promise.resolve()
					: Promise.reject("Should accept privacy policy"),
		},
	],
	NAME: [{ required: true, message: "Name is required!" }],
};
