import React, { useContext } from "react";
import { Row, Col, Card, Button, Form, Input, Checkbox, Alert } from "antd";
import { Link } from "react-router-dom";
import * as types from "../../helpers/constants";
import * as lang from "../../helpers/lang";
import { VALIDATION } from "../../helpers/rules";
import { GlobalContext } from "../../helpers/global-context";

const BusinessRegistrationView = () => {
	const {
		form,
		_onFinishHandler,
		error,
		success,
		_onCheckPerks,
		perks,
	} = useContext(GlobalContext);

	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={6} xs={1}></Col>
				<Col lg={12} xs={22} style={{ marginTop: 50 }}>
					<div className="space-align-block">
						<Card
							// style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.4)" }}
							style={{ borderWidth: 0 }}
						>
							<Row>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22} xs={22}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Link to="/">
											<img
												src={require("../../assets/wescan-square.png")}
												style={{ maxWidth: 150 }}
												alt="WeTrace App"
											/>
										</Link>
									</div>
								</Col>
							</Row>
							<h2
								style={{
									textAlign: "center",
									color: "#2BBB94",
									marginTop: 50,
									marginBottom: 50,
									textTransform: "uppercase",
								}}
							>
								{lang.REGISTER_BUSINESS}
							</h2>
							<Row gutter={16}>
								<Col lg={4} md={3} sm={0} xs={0} />
								<Col lg={16} md={18} sm={24} xs={24}>
									{error && (
										<Alert
											type="error"
											description="Registration failed!"
											style={{ marginBottom: 20 }}
										/>
									)}
									{success && (
										<Alert
											type="success"
											description={
												<div>
													Registration successful! Click{" "}
													<Link to="/login">HERE</Link> to login.
												</div>
											}
											style={{ marginBottom: 20 }}
										/>
									)}
									<Form
										layout="vertical"
										form={form}
										onFinish={_onFinishHandler}
									>
										<Form.Item
											name={types.COMPANY_NAME}
											label={lang.COMPANY_NAME}
											rules={VALIDATION.COMPANY_NAME}
											hasFeedback
										>
											<Input size={"large"} />
										</Form.Item>
										<Form.Item
											name={types.FULL_NAME}
											label={lang.FULL_NAME}
											rules={VALIDATION.FULL_NAME}
											hasFeedback
										>
											<Input size={"large"} />
										</Form.Item>
										<Form.Item
											name={types.MOBILE_NUMBER}
											label={lang.MOBILE_NUMBER}
											rules={VALIDATION.MOBILE_NUMBER}
											hasFeedback
										>
											<Input size={"large"} prefix={`+63`} type="number" />
										</Form.Item>
										<Form.Item
											name={types.EMAIL}
											label={lang.EMAIL}
											rules={VALIDATION.EMAIL}
											hasFeedback
										>
											<Input size={"large"} />
										</Form.Item>
										<Form.Item
											name={types.ADDRESS}
											label={lang.ADDRESS}
											rules={VALIDATION.ADDRESS}
											hasFeedback
										>
											<Input size={"large"} />
										</Form.Item>
										<Form.Item
											name={types.PASSWORD}
											label={lang.PASSWORD}
											rules={VALIDATION.PASSWORD}
											hasFeedback
										>
											<Input.Password size={"large"} />
										</Form.Item>
										<Form.Item
											label={lang.CONFIRM_PASSWORD}
											name={types.CONFIRM_PASSWORD}
											rules={[
												...VALIDATION.CONFIRM_PASSWORD,
												({ getFieldValue }) => ({
													validator(rule, value) {
														if (!value || getFieldValue("password") === value) {
															return Promise.resolve();
														}
														return Promise.reject(
															`${lang.PASSWORD} did not match!`
														);
													},
												}),
											]}
											hasFeedback
										>
											<Input.Password size={"large"} />
										</Form.Item>
										<Form.Item
											name={types.PRIVACY}
											rules={VALIDATION.PRIVACY}
											valuePropName="checked"
										>
											<Checkbox>
												<a
													href="https://wescan.ph/pricacy.html"
													target="_blank"
												>
													{lang.PRIVACY}
												</a>
											</Checkbox>
										</Form.Item>
										<Form.Item name={types.PERKS}>
											<Checkbox onChange={_onCheckPerks} checked={perks}>
												{lang.PERKS}
											</Checkbox>
										</Form.Item>
										<div style={{ textAlign: "center" }}>
											<Button
												htmlType="submit"
												size="large"
												style={{
													backgroundColor: "#2BBB94",
													color: "white",
													textTransform: "uppercase",
													padding: "5px 25px",
												}}
											>
												{lang.SUBMIT}
											</Button>
										</div>
									</Form>
								</Col>
							</Row>
							{/* <div style={{ paddingBottom: 50 }}>
								<h3 style={{ textAlign: "center", marginBottom: 20 }}>
									Download WeTrace App
								</h3>
								<Row gutter={16}>
									<Col lg={7} sm={0} xs={0}></Col>
									<Col lg={5} sm={12} xs={12}>
										<div
											onClick={() => {
												window.location.href =
													"https://play.google.com/store/apps/details?id=com.dxform.ph";
											}}
										>
											<img
												src={require("../../assets/google.jpg")}
												style={{ width: "100%" }}
												alt="Play Store"
											/>
										</div>
									</Col>
									<Col lg={5} sm={12} xs={12}>
										<div
											onClick={() => {
												window.location.href =
													"https://apps.apple.com/ph/app/wetrace-ph/id1506567245";
											}}
										>
											<img
												src={require("../../assets/apple.jpg")}
												style={{ width: "100%" }}
												alt="App Store"
											/>
										</div>
									</Col>
									<Col lg={7} sm={0} xs={0}></Col>
								</Row>
							</div> */}
						</Card>
					</div>
				</Col>
				<Col lg={6} xs={1}></Col>
			</Row>
		</div>
	);
};

export default BusinessRegistrationView;
