const COMPANY_NAME = "Company name";
const FULL_NAME = "Full name";
const MOBILE_NUMBER = "Mobile number";
const EMAIL = "Email address";
const ADDRESS = "Complete address";
const PASSWORD = "Password";
const CONFIRM_PASSWORD = "Confirm password";
const REGISTER_BUSINESS = "Register your business";
const SUBMIT = "Submit";
const PRIVACY = "Accept Privacy Policy and Terms of Use";
const SIGN_IN = "Sign In";
const NAME = "Name";
const TEMPERATURE = "Body temperature";
const FIRST_NAME = "First name";
const LAST_NAME = "Last name";
const PERKS =
	"Allow WeScan and affiliate companies to send marketing promos for the latest discounts and perks.";

export {
	COMPANY_NAME,
	FULL_NAME,
	MOBILE_NUMBER,
	EMAIL,
	PASSWORD,
	ADDRESS,
	CONFIRM_PASSWORD,
	REGISTER_BUSINESS,
	SUBMIT,
	PRIVACY,
	SIGN_IN,
	NAME,
	TEMPERATURE,
	FIRST_NAME,
	LAST_NAME,
	PERKS,
};
