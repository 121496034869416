import React, { useContext } from "react";
import DashboardTemplate from "../../../template/dashboard";
import { Row, Col, Statistic, Table } from "antd";
import { UserOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../helpers/global-context";

const BusinessDashboardView = () => {
	const { visitor_logs } = useContext(GlobalContext);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Temperature",
			dataIndex: "temperature",
			key: "temperature",
		},
		{
			title: "Scanner name",
			dataIndex: "scanner_name",
			key: "scanner_name",
		},
		{
			title: "Scanned date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Mobile number",
			dataIndex: "mobile_number",
			key: "mobile_number",
		},
	];

	return (
		<Row gutter={16}>
			<Col lg={24} sm={24} xs={24}>
				<h1>Log History</h1>
				<div style={{ marginTop: 50 }}>
					<Table columns={columns} dataSource={visitor_logs} />
				</div>
			</Col>
		</Row>
	);
};

export default BusinessDashboardView;
