import React, { useContext } from "react";
import { Row, Col, Form, Input, Button, Alert, Upload } from "antd";
import * as lang from "../../../helpers/lang";
import * as types from "../../../helpers/constants";
import { VALIDATION } from "../../../helpers/rules";
import { GlobalContext } from "../../../helpers/global-context";
import { UploadOutlined } from "@ant-design/icons";

const BusinessProfileView = () => {
  const {
    handleUpload,
    spinning,
    handleUserIdImageChange,
    image,
		company_data,
		updating
  } = useContext(GlobalContext);

  const default_image =
    "https://pasajob.s3.amazonaws.com/doh_feeds_items/wescanpic-2020-12-14 2:9:40.png";

  return (
    <Row gutter={16}>
      <Col lg={24} sm={24} xs={24}>
        <h1 style={{ marginBottom: 50 }}>PROFILE</h1>
        <p>Set company profile photo</p>
        <img
          src={
            company_data?.wescan_companies?.[0]?.profile_image ?? default_image
          }
          style={{
            height: 150,
            width: 150,
            objectFit: "contain",
            border: "1px solid #ccc",
            padding: 10,
            borderRadius: 10,
          }}
        />
        <br />
        <br />
        <Upload beforeUpload={handleUserIdImageChange} accept=".png,.jpg,.jpeg">
          <Button icon={<UploadOutlined />}>Upload your company photo</Button>
        </Upload>
        <Button
          onClick={handleUpload}
          disabled={!image||updating||spinning}
          type="primary"
          style={{ marginTop: 5 }}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};

export default BusinessProfileView;
