const { gql } = require("@apollo/client");

const INSERT_APPLICATION = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
	) {
		insert_workpass_applications(objects: $data) {
			returning {
				id
				company_name
				destination_barangay
				destination_location
				destination_municipality
				destination_province
				first_name
				municipality_code
				office_number
				origin_barangay
				origin_location
				origin_municipality
				origin_province
				status
				unique_id
				workpass_application_files(where: { type: { _eq: "picture" } }) {
					url
				}
				last_name
			}
		}
	}
`;

const INSERT_USER = gql`
	mutation insert_workpass_application(
		$data: [workpass_applications_insert_input!]!
		$unique_id: String
	) {
		insert_mobile_app_users(objects: { unique_installation_id: $unique_id }) {
			returning {
				id
			}
		}
		insert_workpass_applications(objects: $data) {
			returning {
				id
				company_name
				destination_barangay
				destination_location
				destination_municipality
				destination_province
				first_name
				municipality_code
				office_number
				origin_barangay
				origin_location
				origin_municipality
				origin_province
				status
				unique_id
				workpass_application_files(where: { type: { _eq: "picture" } }) {
					url
				}
				last_name
			}
		}
	}
`;

const LOGIN_USER = gql`
	mutation wescan_user_login(
		$mobile_number: String
		$password: String
		$type: String
	) {
		wescan_user_login(
			object: {
				mobile_number: $mobile_number
				password: $password
				type: $type
			}
		) {
			token
		}
	}
`;

const CREATE_LOGIN_USER = gql`
	mutation map_login($username: String!, $password: String!) {
		map_login(object: { username: $username, password: $password }) {
			token
		}
	}
`;

const UPDATE_APPLICATION_STATUS = gql`
	mutation update_application_status(
		$id: Int
		$status: String
		$notes: String
		$is_admin: Boolean
	) {
		update_workpass_applications(
			where: { id: { _eq: $id } }
			_set: { status: $status, notes: $notes, is_admin: $is_admin }
		) {
			affected_rows
			returning {
				id
				status
				unique_id
			}
		}
	}
`;

const UPDATE_STATUS_BY_UNIQUE_ID = gql`
	mutation update_status($unique_id: String, $id: Int) {
		update_workpass_applications(
			where: {
				unique_id: { _eq: $unique_id }
				status: { _neq: "Approved" }
				id: { _lt: $id }
			}
			_set: { status: "Denied" }
		) {
			affected_rows
		}
	}
`;

const UPDATE_OSLOB = gql`
	mutation {
		update_workpass_applications(
			where: {
				origin_municipality: { _eq: "OSLOB" }
				municipality_code: { _eq: "D1-01-0001" }
			}
			_set: { municipality_code: "D2-05-0001" }
		) {
			affected_rows
		}
	}
`;

const UPDATE_ALCANTARA = gql`
	mutation {
		update_workpass_applications(
			where: {
				origin_municipality: { _eq: "ALCANTARA" }
				municipality_code: { _eq: "D1-01-0001" }
			}
			_set: { municipality_code: "D7-06-0001" }
		) {
			affected_rows
		}
	}
`;

const REMOVE_APPLICATION_WITHOUT_FILES = gql`
	mutation application_files {
		update_workpass_applications(
			where: {
				_or: [
					{ _not: { workpass_application_files: { type: { _eq: "picture" } } } }
					{
						_not: {
							workpass_application_files: {
								type: { _eq: "medical_certificate" }
							}
						}
					}
					{
						_not: {
							workpass_application_files: { type: { _eq: "company_id" } }
						}
					}
					{
						_not: {
							workpass_application_files: {
								type: { _eq: "certificate_of_employment" }
							}
						}
					}
				]
				status: { _eq: "Pending" }
			}
			_set: {
				status: "Denied"
				notes: "For denied applications, you can apply again but make sure all submitted documents are complete and correct. You can also coordinate with your LGU via phone or their social media channels."
			}
		) {
			returning {
				id
			}
		}
	}
`;

const REGISTER_COMPANY = gql`
	mutation insert_company(
		$mobile_number: String
		$company_name: String
		$full_name: String
		$address: String
		$password: String
		$email: String
		$perks: Boolean
	) {
		wescan_company_register(
			object: {
				mobile_number: $mobile_number
				company_name: $company_name
				full_name: $full_name
				address: $address
				password: $password
				email: $email
				perks: $perks
			}
		) {
			token
		}
	}
`;

const INSERT_SCANNER = gql`
	mutation insert_scanner($data: [wescan_company_scanners_insert_input!]!) {
		insert_wescan_company_scanners(objects: $data) {
			returning {
				id
			}
		}
	}
`;

const INSERT_VISITOR = gql`
	mutation insert_visitor($data: [wescan_visitors_insert_input!]!) {
		insert_wescan_visitors(objects: $data) {
			returning {
				id
				temperature
			}
		}
	}
`;

const CREATE_WESCAN_USER = gql`
	mutation create_user($data: [wescan_users_insert_input!]!) {
		insert_wescan_users(objects: $data) {
			returning {
				id
				mobile_number
			}
		}
	}
`;

const GENERATE_OTP_CODE = gql`
	mutation generate_otp($mobile_number: String, $type: String) {
		wescan_otp_generator(
			object: { mobile_number: $mobile_number, type: $type }
		) {
			code
			message
		}
	}
`;

const SEND_VERIFICATION_CODE = gql`
	mutation send_code($mobile_number: String) {
		wescan_code_sender(object: { mobile_number: $mobile_number }) {
			code
			message
		}
	}
`;

const SEND_BUSINESS_NOTIFICATIONS = gql`
	mutation($object: WeScanBusinessNotificationInput) {
		wescan_business_notification(object: $object) {
			notified_customers
			message
		}
	}
`;

const UPDATE_PROFILE_IMAGE = gql`
mutation MyMutation($image: String, $id: uuid) {
  update_wescan_companies(where: {id: {_eq: $id}}, _set: {profile_image: $image}) {
    affected_rows
  }
}
`

export {
	INSERT_APPLICATION,
	LOGIN_USER,
	UPDATE_APPLICATION_STATUS,
	INSERT_USER,
	UPDATE_STATUS_BY_UNIQUE_ID,
	UPDATE_OSLOB,
	UPDATE_ALCANTARA,
	REMOVE_APPLICATION_WITHOUT_FILES,
	REGISTER_COMPANY,
	CREATE_LOGIN_USER,
	INSERT_SCANNER,
	INSERT_VISITOR,
	CREATE_WESCAN_USER,
	GENERATE_OTP_CODE,
	SEND_VERIFICATION_CODE,
	SEND_BUSINESS_NOTIFICATIONS,
	UPDATE_PROFILE_IMAGE
};
