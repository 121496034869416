import React, { useContext, useRef } from "react";
import DashboardTemplate from "../../../template/dashboard";
import { Row, Col, Table, Button, Drawer, Form, Input, Modal } from "antd";
import { GlobalContext } from "../../../helpers/global-context";
import * as types from "../../../helpers/constants";
import * as lang from "../../../helpers/lang";
import { VALIDATION } from "../../../helpers/rules";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";

class ComponentToPrint extends React.Component {
  render() {
    const item = this.props.item
    const default_image =
    "https://pasajob.s3.amazonaws.com/doh_feeds_items/wescanpic-2020-12-14 2:9:40.png";
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          width:'auto',
        }}
      >
        <div
        className="w-full"
        style={{
          backgroundImage: `url(${require("../../../assets/scanner-qr-template.png")})`,
          height: 800,
          width: '100%',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          position:'relative'
        }}>
          <div style={{position:'absolute',top:180,left:131}}>
            <QRCode value={item?.code} size={215}/>
          </div>
          <div style={{position:'absolute',top:480,left:81, backgroundColor:'white', width: 300, height: 50,
          display: 'flex',flexDirection:'row', justifyContent: 'center'}}>
            <img src={default_image} style={{height:50,width:50,objectFit:'contain'}}/>
            <p style={{fontSize: 16,marginTop:5}}>{item?.name}</p>
          </div>
        </div>
      </div>
    );
  }
}

const BusinessScannersView = () => {
  const {
    total,
    scanners,
    drawer,
    setDrawer,
    form,
    _finishHandler,
    visible,
    setVisible,
    item,
    setItem,
  } = useContext(GlobalContext);

  const componentRef = useRef();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <a
          onClick={() => {
            setVisible(true);
            setItem(item);
          }}
        >
          Print QR Code
        </a>
      ),
    },
  ];
  return (
    <Row gutter={16}>
      <Drawer
        title="Add Scanner"
        placement="right"
        closable={true}
        onClose={() => setDrawer(false)}
        visible={drawer}
        width={500}
      >
        <Form form={form} layout="vertical" onFinish={_finishHandler}>
          <Form.Item
            name={types.NAME}
            rules={VALIDATION.NAME}
            label={lang.NAME}
            hasFeedback
          >
            <Input size="large" placeholder="Scanner name" />
          </Form.Item>
          <Button htmlType="submit" size="large" type="primary">
            SUBMIT
          </Button>
        </Form>
      </Drawer>
      <Col lg={16} sm={24} xs={24}>
        <h1>Scanners</h1>
        <div style={{ margin: "10px 0" }}>
          <Button type="primary" onClick={() => setDrawer(true)}>
            Add Scanner
          </Button>
        </div>
        <Table columns={columns} dataSource={scanners} />
      </Col>

      <Modal
        visible={visible}
        okButtonProps={{ style: {display: "none"} }}
        onCancel={() => setVisible(false)}
        bodyStyle={{width:500}}
      >
        <div>
          <ReactToPrint
            trigger={() => <Button type="primary">Print</Button>}
            content={() => componentRef.current}
          />
          <ComponentToPrint ref={componentRef} item={item}/>
        </div>
      </Modal>
    </Row>
  );
};

export default BusinessScannersView;
