import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import DashboardTemplate from "../../../template/dashboard";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
	COMPANY_INFO,
	COMPANY_SCANNERS,
	GET_VISITOR,
	GET_WORKPASS,
} from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import moment from "moment";
import { Form } from "antd";
import randomize from "randomatic";
import {
	INSERT_SCANNER,
	INSERT_VISITOR,
	CREATE_WESCAN_USER,
} from "../../../graphql/muattions";
import BusinessScanQRView from "./business-scan-qr-view";
import { useParams } from "react-router-dom";

const BusinessScanQRController = () => {
	const { id } = useParams();
	const [company_id, setCompanyId] = useState(null);
	const [scanners, setScanners] = useState([]);
	const [scanner, setScanner] = useState(null);
	const [showScanner, setShowScanner] = useState(false);
	const [show_temperature, setShowTemperature] = useState(false);
	const [temperature, setTemperature] = useState(null);
	const [drawer, setDrawer] = useState(false);
	const [visitor, setVisitor] = useState({ scanned: false });
	const [form] = Form.useForm();
	const [is_workpass, setIsWorkpass] = useState(false);
	const [scanned, setScanned] = useState(false);

	const { data, error } = useQuery(COMPANY_INFO, {
		variables: {
			mobile_number: localStorage.getItem(types.MOBILE_NUMBER),
		},
		fetchPolicy: "network-only",
	});

	const [getWorkpass, { data: workpass }] = useLazyQuery(GET_WORKPASS, {
		fetchPolicy: "network-only",
	});

	const [getCompanyScanners, { data: company_scanners }] = useLazyQuery(
		COMPANY_SCANNERS,
		{
			fetchPolicy: "network-only",
		}
	);

	const [fetchVisitor, { data: visitor_data }] = useLazyQuery(GET_VISITOR, {
		fetchPolicy: "network-only",
	});

	const [insertVisitor] = useMutation(INSERT_VISITOR, {
		onCompleted(res) {
			setVisitor({
				...visitor,
				scanned: false,
				temperature: res.insert_wescan_visitors.returning[0].temperature,
			});
			setTemperature(null);
			setShowTemperature(false);
			form.setFieldsValue({ [types.TEMPERATURE]: null });
		},
	});

	const [createUser] = useMutation(CREATE_WESCAN_USER, {
		onCompleted(res) {
			console.log(res);
			const wescan_user_id = res.insert_wescan_users.returning[0].id;
			insertVisitor({
				variables: {
					data: {
						wescan_company_id: company_id,
						wescan_company_scanner_id: id,
						wescan_user_id,
						temperature,
					},
				},
			});
			setTemperature(null);
			setShowTemperature(false);
			form.setFieldsValue({ [types.TEMPERATURE]: null });
		},
	});

	useEffect(() => {
		if (company_id) {
			getCompanyScanners({
				variables: {
					company_id,
				},
			});
		}
	}, [company_id]);

	useEffect(() => {
		if (id) {
			setScanner(id);
		}
	}, [id]);

	useEffect(() => {
		if (data && data?.wescan_companies?.length) {
			const company = data?.wescan_companies?.[0];
			setCompanyId(company.id);
		}
	}, [data]);

	useEffect(() => {
		if (company_scanners?.wescan_company_scanners.length) {
			const data = company_scanners.wescan_company_scanners.map((scanner) => {
				const { name, code, created_at, id } = scanner;
				return {
					id,
					name,
					code,
					created_date: moment(created_at).format("LL"),
				};
			});
			setScanners(data);
		}
	}, [company_scanners]);

	useEffect(() => {
		if (
			visitor_data &&
			visitor_data?.wescan_users?.length &&
			!visitor.scanned
		) {
			setShowTemperature(true);
		} else if (visitor_data && !visitor_data?.wescan_users?.length) {
			alert("QR code invalid or doesn`t exists!");
		}
	}, [visitor_data]);

	useEffect(() => {
		if (temperature && visitor_data?.wescan_users?.length) {
			setVisitor({ ...visitor_data.wescan_users[0], scanned: true });
			setShowTemperature(false);
		}
	}, [temperature, visitor_data]);

	useEffect(() => {
		if (
			visitor &&
			visitor.scanned &&
			visitor?.id &&
			id &&
			company_id &&
			!is_workpass
		) {
			insertVisitor({
				variables: {
					data: {
						wescan_company_id: company_id,
						wescan_company_scanner_id: id,
						wescan_user_id: visitor.id,
						temperature,
					},
				},
			});
			setShowTemperature(false);
		}
	}, [visitor, id, data, visitor.scanned, temperature, is_workpass]);

	useEffect(() => {
		if (
			workpass &&
			workpass?.workpass_applications?.length &&
			temperature &&
			is_workpass
		) {
			const {
				first_name,
				last_name,
				mobile_number,
				origin_location,
			} = workpass.workpass_applications[0];
			createUser({
				variables: {
					data: {
						first_name,
						last_name,
						mobile_number,
						address: origin_location,
					},
				},
			});
			setVisitor({
				first_name,
				last_name,
				mobile_number,
				scanned: true,
				address: origin_location,
			});
		} else if (
			workpass &&
			workpass?.workpass_applications?.length &&
			is_workpass
		) {
			setShowTemperature(true);
		} else if (workpass && !workpass?.workpass_applications?.length) {
			alert("QR code invalid or doesn`t exists!");
		}
	}, [workpass, temperature, is_workpass]);

	const _scanHandler = (code) => {
		if (code && !temperature) {
			setScanned(true);
			if (code.length > 5) {
				getWorkpass({
					variables: {
						unique_id: code,
					},
				});
				setIsWorkpass(true);
			} else {
				fetchVisitor({
					variables: {
						code,
					},
				});
				setIsWorkpass(false);
			}
		}
	};

	const _errorHandler = () => {
		console.log("error");
	};

	const _onFinishHandler = (data) => {
		setTemperature(data[types.TEMPERATURE]);
		setShowTemperature(false);
	};

	const _onScanHandler = ({ code }) => {
		if (code) {
			setScanned(true);
			code = code.toUpperCase();
			fetchVisitor({
				variables: {
					code,
				},
			});
			setIsWorkpass(false);
		}
	};

	const _resetScanner = () => {
		setScanned(false);
		setTemperature(null);
		setVisitor({ scanned: false });
		form.setFieldsValue({ code: null });
	};

	const values = {
		id,
		scanners,
		drawer,
		form,
		show_temperature,
		setShowTemperature,
		temperature,
		setTemperature,
		setDrawer,
		scanner,
		setScanner,
		showScanner,
		visitor,
		scanned,
		setShowScanner,
		_scanHandler,
		_errorHandler,
		_onFinishHandler,
		_onScanHandler,
		_resetScanner,
	};

	return (
		<GlobalContext.Provider value={values}>
			<BusinessScanQRView />
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(BusinessScanQRController);
