import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, AppContext } from "../../helpers/global-context";
import LoginView from "./login-view";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { LOGIN } from "../../helpers/services";
import axios from "axios";
import * as types from "../../helpers/constants";
import { Form } from "antd";

const LoginController = () => {
	const { setCookie, cookies } = useContext(AppContext);
	const [error, setError] = useState(false);
	const [spin, setSpin] = useState(false);
	const history = useHistory();
	const [form] = Form.useForm();

	const _finishHandler = async (data) => {
		data = { ...data, [types.MOBILE_NUMBER]: `63${data[types.MOBILE_NUMBER]}` };
		setSpin(true);
		const res = await LOGIN(data);
		if (res.status === 200 && res?.data?.data?.wescan_user_login?.token) {
			setCookie("token", res?.data?.data?.wescan_user_login?.token, {
				path: "/",
				expires: moment().add(8, "hours").toDate(),
			});
			localStorage.setItem("mobile_number", data[types.MOBILE_NUMBER]);
			window.location.reload();
		} else {
			setError(true);
			setSpin(false);
			form.setFieldsValue({ [types.PASSWORD]: "" });
		}
	};

	const values = {
		_finishHandler,
		error,
		spin,
		form,
	};

	return (
		<GlobalContext.Provider value={values}>
			<LoginView />
		</GlobalContext.Provider>
	);
};

export default LoginController;
