import React from "react";
import { Row, Col, Card, Button } from "antd";
import { Link } from "react-router-dom";
import BaseTemplate from "../../template/base";

const HomepageView = () => {
	return (
		<div className="space-align-container">
			<Row gutter={16}>
				<Col lg={6} xs={1}></Col>
				<Col lg={12} xs={22} style={{ marginTop: 50 }}>
					<div className="space-align-block">
						<Card
							// style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.4)" }}
							style={{ borderWidth: 0 }}
						>
							<Row>
								<Col lg={6} sm={1}></Col>
								<Col lg={12} sm={22} xs={22}>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Link to="/">
											<img
												src={require("../../assets/wescan-square.png")}
												style={{ maxWidth: 200 }}
												alt="WeTrace App"
											/>
										</Link>
									</div>
								</Col>
							</Row>
							<h2
								style={{ textAlign: "center", color: "#2BBB94", marginTop: 50 }}
							>
								WeScan BUSINESS PORTAL
							</h2>
							<div style={{ marginTop: 30, marginBottom: 50 }}>
								<Row gutter={16}>
									<Col lg={4} xs={1}></Col>
									<Col lg={8} xs={24}>
										<Button
											size="large"
											block
											style={{
												borderColor: "#2BBB94",
												backgroundColor: "#2BBB94",
												color: "#ffffff",
												height: 70,
												fontSize: 14,
												marginTop: 20,
												letterSpacing: 2,
											}}
											shape="round"
										>
											<Link to="/business-registration">REGISTER BUSINESS</Link>
										</Button>
									</Col>
									<Col lg={8} xs={24}>
										<Button
											size="large"
											block
											style={{
												borderColor: "#2BBB94",
												backgroundColor: "#2BBB94",
												color: "#ffffff",
												height: 70,
												fontSize: 14,
												marginTop: 20,
												letterSpacing: 2,
											}}
											shape="round"
										>
											<Link to="/login">LOGIN</Link>
										</Button>
									</Col>
									<Col lg={4} xs={1}></Col>
								</Row>
							</div>
							{/* <div style={{ paddingBottom: 50 }}>
								<h3 style={{ textAlign: "center", marginBottom: 20 }}>
									Download WeTrace App
								</h3>
								<Row gutter={16}>
									<Col lg={7} sm={0} xs={0}></Col>
									<Col lg={5} sm={12} xs={12}>
										<div
											onClick={() => {
												window.location.href =
													"https://play.google.com/store/apps/details?id=com.dxform.ph";
											}}
										>
											<img
												src={require("../../assets/google.jpg")}
												style={{ width: "100%" }}
												alt="Play Store"
											/>
										</div>
									</Col>
									<Col lg={5} sm={12} xs={12}>
										<div
											onClick={() => {
												window.location.href =
													"https://apps.apple.com/ph/app/wetrace-ph/id1506567245";
											}}
										>
											<img
												src={require("../../assets/apple.jpg")}
												style={{ width: "100%" }}
												alt="App Store"
											/>
										</div>
									</Col>
									<Col lg={7} sm={0} xs={0}></Col>
								</Row>
							</div> */}
						</Card>
					</div>
				</Col>
				<Col lg={6} xs={1}></Col>
			</Row>
		</div>
	);
};

export default HomepageView;
