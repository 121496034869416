import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import BusinessDashboardView from "./business-dashboard-view";
import DashboardTemplate from "../../../template/dashboard";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_LOGS, COMPANY_INFO } from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import moment from "moment";

const BusinessDashboardController = () => {
	const [company_id, setCompanyId] = useState(null);
	const [visitor_logs, setVisitorLogs] = useState(null);

	const { data } = useQuery(COMPANY_INFO, {
		variables: {
			mobile_number: localStorage.getItem(types.MOBILE_NUMBER),
		},
		fetchPolicy: "network-only",
	});

	const [getLogs, { data: logs, error }] = useLazyQuery(GET_LOGS, {
		fetchPolicy: "network-only",
	});

	console.log(error, company_id);

	useEffect(() => {
		if (data && data?.wescan_companies?.length) {
			const company = data?.wescan_companies?.[0];
			setCompanyId(company.id);
		}
	}, [data]);

	useEffect(() => {
		if (company_id) {
			getLogs({
				variables: {
					company_id,
				},
			});
		}
	}, [company_id]);

	useEffect(() => {
		if (logs) {
			console.log(logs);
			let data = logs.wescan_visitors.map((visitor) => {
				const {
					scanner: { name: scanner_name },
					user: { first_name, last_name, mobile_number },
					temperature,
					created_at,
				} = visitor;
				return {
					name: `${first_name} ${last_name}`,
					mobile_number,
					temperature,
					scanner_name,
					date: moment(created_at).format("LLL"),
				};
			});
			setVisitorLogs(data);
		}
	}, [logs]);

	const values = { visitor_logs };

	return (
		<GlobalContext.Provider value={values}>
			<BusinessDashboardView />
		</GlobalContext.Provider>
	);
};

export default DashboardTemplate(BusinessDashboardController);
