import * as types from "../helpers/constants";

export const initialState = {
	first_name: null,
	last_name: null,
	company_name: null,
	origin_province: null,
	origin_municipality: null,
	origin_barangay: null,
	origin_location: null,
	destination_province: null,
	destination_municipality: null,
	destination_barangay: null,
	destination_location: null,
	unique_id: null,
	municipality_code: null,
	status: "Approved",
	mobile_number: null,
};

export const initialSteps = {
	step_1: false,
	step_2: false,
	step_3: false,
	step_4: false,
};

export const COMPANY_REGISTRATION = {
	[types.COMPANY_NAME]: null,
	[types.FULL_NAME]: null,
	[types.MOBILE_NUMBER]: null,
	[types.ADDRESS]: null,
	[types.EMAIL]: null,
	[types.PASSWORD]: null,
};

export const CREATE_USER = {
	[types.FIRST_NAME]: null,
	[types.LAST_NAME]: null,
	[types.MOBILE_NUMBER]: null,
};
