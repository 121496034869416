import React from "react";
import { Layout, Menu } from "antd";
import {
	CloseOutlined,
	UserOutlined,
	CheckOutlined,
	LogoutOutlined,
	HomeOutlined,
	QrcodeOutlined,
	BarChartOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Content, Footer, Sider } = Layout;

const DashboardTemplate = (Component) => {
	return class extends React.Component {
		state = {
			selectedKeys: ["0"],
		};

		UNSAFE_componentWillMount() {
			switch (this.props?.history?.location?.pathname) {
				case "/business-dashboard":
					this.setState({ selectedKeys: ["0"] });
					break;
				case "/business-scanners":
					this.setState({ selectedKeys: ["1"] });
					break;
				case "/business-scan-qr":
					this.setState({ selectedKeys: ["2"] });
					break;
				case "/business-create-qr":
					this.setState({ selectedKeys: ["3"] });
					break;
				case "/profile":
					this.setState({ selectedKeys: ["8"] });
					break;
				case "/lgu-dxform-all":
					this.setState({ selectedKeys: ["7"] });
					break;
				case "/reports":
					this.setState({ selectedKeys: ["5"] });
					break;
				case "/business-notify-customers":
					this.setState({ selectedKeys: ["4"] });
					break;
				default:
					break;
			}
		}

		render() {
			return (
				<Layout>
					<Sider
						breakpoint="lg"
						collapsedWidth="0"
						style={{ backgroundColor: "white" }}
					>
						<div style={{ margin: "10px 0", padding: 10 }}>
							<img
								src={require("../assets/wescan-square.png")}
								style={{ width: "100%" }}
								alt="WeTrace App"
							/>
						</div>
						<Menu mode="inline" defaultSelectedKeys={this.state.selectedKeys}>
							<Menu.Item key="0" icon={<HomeOutlined />}>
								<Link to="/business-dashboard">Home</Link>
							</Menu.Item>
							<Menu.Item key="8" icon={<QrcodeOutlined />}>
								<Link to="/profile">Profile</Link>
							</Menu.Item>
							<Menu.Item key="1" icon={<QrcodeOutlined />}>
								<Link to="/business-scanners">Scanners</Link>
							</Menu.Item>
							<Menu.Item key="2" icon={<QrcodeOutlined />}>
								<Link to="/business-scan-qr">Scan QR</Link>
							</Menu.Item>
							<Menu.Item key="3" icon={<QrcodeOutlined />}>
								<Link to="/business-create-qr">Create Pass Code</Link>
							</Menu.Item>
							<Menu.Item key="4" icon={<QrcodeOutlined />}>
								<Link to="/business-notify-customers">Notify Customers</Link>
							</Menu.Item>
							<Menu.Item key="6" icon={<LogoutOutlined />}>
								<Link to="/logout">Logout</Link>
							</Menu.Item>
						</Menu>
					</Sider>
					<Layout>
						<Content style={{ margin: "24px 16px 0" }}>
							<div
								style={{
									padding: 24,
									minHeight: 500,
									backgroundColor: "white",
								}}
							>
								<Component />
							</div>
						</Content>
						<Footer style={{ textAlign: "center" }}>
							&copy;2020 WeScan App
						</Footer>
					</Layout>
				</Layout>
			);
		}
	};
};

export default DashboardTemplate;
