import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RegistrationpageController from "../screens/registration-page/registrationpage-controller";
import makeApolloClient from "../helpers/client";
import { ApolloProvider } from "@apollo/client";
import LoginController from "../screens/login/login-controller";
import { AppContext } from "../helpers/global-context";
import Authenticated from "./authenticated";
import { CREATE_TOKEN } from "../helpers/services";
import { useCookies } from "react-cookie";
import LogoutController from "../screens/management/logout/logout-controller";
import HomepageController from "../screens/home-page/homepage-controller";
import BusinessRegistrationController from "../screens/business-registration/business-registration-controller";
import BusinessDashboardController from "../screens/management/business-dashboard/business-dashboard-controller";
import BusinessScannersController from "../screens/management/business-scanners/business-scanner-controller";
import BusinessScanQrController from "../screens/management/business-scan-qr/business-scan-qr-controller";
import BusinessCreateQrController from "../screens/management/business-create-qr/business-create-qr-controller";
import NotifyCustomersController from "../screens/management/notify-customers/notify-customers-controller";
import BusinessProfileController from "../screens/management/business-profile/business-profile-controller";

const AppRouter = () => {
	const code = localStorage.getItem("municipality_code");
	const [client, setClient] = useState(null);
	const [cookies, setCookie, removeCookie] = useCookies(["name"]);
	const [token, setToken] = useState(null);
	const [municipality_code] = useState(code ?? null);

	const generateToken = async () => {
		const generatedToken = await CREATE_TOKEN({
			username: process.env.REACT_APP_USERNAME,
			password: process.env.REACT_APP_PASSWORD,
		});
		const client = makeApolloClient(generatedToken);
		setClient(client);
	};

	useEffect(() => {
		if (token) {
			const client = makeApolloClient(token);
			setClient(client);
		}
	}, [token]);

	useEffect(() => {
		if (!client) {
			if (cookies.token) {
				const client = makeApolloClient(cookies.token);
				setClient(client);
			} else {
				generateToken();
			}
		}
	}, [client, cookies]);

	const _logoutHandler = () => {
		console.log("logout");
	};

	const values = {
		token,
		cookies,
		municipality_code,
		setCookie,
		removeCookie,
		setToken,
		_logoutHandler,
	};

	if (!client) return null;

	return (
		<Router>
			<ApolloProvider client={client}>
				<AppContext.Provider value={values}>
					<Switch>
						<Authenticated
							path="/work-pass"
							exact
							component={RegistrationpageController}
						/>
						<Authenticated path="/" exact component={HomepageController} />
						<Route
							path="/business-registration"
							exact
							component={BusinessRegistrationController}
						/>
						<Authenticated path="/login" exact component={LoginController} />
						<Authenticated
							path="/business-dashboard"
							exact
							component={BusinessDashboardController}
						/>
						<Authenticated
							path="/business-scanners"
							exact
							component={BusinessScannersController}
						/>
						<Authenticated
							path="/business-scan-qr/:id?"
							exact
							component={BusinessScanQrController}
						/>
						<Authenticated
							path="/business-create-qr"
							exact
							component={BusinessCreateQrController}
						/>
						<Authenticated
							path="/profile"
							exact
							component={BusinessProfileController}
						/>
						<Authenticated path="/logout" exact component={LogoutController} />
						<Authenticated path="/business-notify-customers" exact component={NotifyCustomersController} />
					</Switch>
				</AppContext.Provider>
			</ApolloProvider>
		</Router>
	);
};

export default AppRouter;
