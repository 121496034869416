import React, { useState } from "react";
import { GlobalContext } from "../../helpers/global-context";
import BusinessRegistrationView from "./business-registration-view";
import { Form } from "antd";
import { REGISTER } from "../../helpers/services";

const BusinessRegistrationController = () => {
	const [form] = Form.useForm();
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [perks, setPerks] = useState(true);

	const _onFinishHandler = async (data) => {
		data = { ...data, mobile_number: `63${data.mobile_number}`, perks };
		setSuccess(false);
		setError(false);
		const res = await REGISTER(data);
		if (res.status === 200 && res?.data?.data?.wescan_company_register?.token) {
			setSuccess(true);
			form.resetFields();
		} else {
			setError(true);
		}
	};

	const _onCheckPerks = (checked) => {
		setPerks(checked.target.checked);
	};

	const values = {
		form,
		success,
		error,
		perks,
		_onFinishHandler,
		_onCheckPerks,
	};

	return (
		<GlobalContext.Provider value={values}>
			<BusinessRegistrationView />
		</GlobalContext.Provider>
	);
};

export default BusinessRegistrationController;
