import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../helpers/global-context";
import BusinessScannersView from "./business-scanners-view";
import DashboardTemplate from "../../../template/dashboard";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { COMPANY_INFO, COMPANY_SCANNERS } from "../../../graphql/queries";
import * as types from "../../../helpers/constants";
import moment from "moment";
import { Form } from "antd";
import randomize from "randomatic";
import { INSERT_SCANNER } from "../../../graphql/muattions";

const BusinessScannersController = () => {
  const [company_id, setCompanyId] = useState(null);
  const [scanners, setScanners] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();

  const { data, error } = useQuery(COMPANY_INFO, {
    variables: {
      mobile_number: localStorage.getItem(types.MOBILE_NUMBER),
    },
    fetchPolicy: "network-only",
  });

  const [getCompanyScanners, { data: company_scanners }] = useLazyQuery(
    COMPANY_SCANNERS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [insertScanner] = useMutation(INSERT_SCANNER, {
    onCompleted(res) {
      window.location.reload();
    },
  });

  useEffect(() => {
    if (company_id) {
      getCompanyScanners({
        variables: {
          company_id,
        },
      });
    }
  }, [company_id]);

  useEffect(() => {
    if (data && data?.wescan_companies?.length) {
      const company = data?.wescan_companies?.[0];
      setCompanyId(company.id);
    }
  }, [data]);

  useEffect(() => {
    if (company_scanners?.wescan_company_scanners.length) {
      const data = company_scanners.wescan_company_scanners.map((scanner) => {
        const { name, code, created_at, id, wescan_company_id } = scanner;
        return {
          id,
          wescan_company_id,
          name,
          code,
          created_date: moment(created_at).format("LL"),
        };
      });
      setScanners(data);
    }
  }, [company_scanners]);

  const _finishHandler = (data) => {
    data = {
      ...data,
      [types.COMPANY_ID]: company_id,
      [types.CODE]: randomize("A0", 5),
    };
    insertScanner({
      variables: {
        data,
      },
    });
  };

  const values = {
    scanners,
    drawer,
    form,
    setDrawer,
    _finishHandler,
    visible,
    setVisible,
    item,
    setItem,
    data
  };

  return (
    <GlobalContext.Provider value={values}>
      <BusinessScannersView />
    </GlobalContext.Provider>
  );
};

export default DashboardTemplate(BusinessScannersController);
