const COMPANY_NAME = "company_name";
const FULL_NAME = "full_name";
const MOBILE_NUMBER = "mobile_number";
const ADDRESS = "address";
const EMAIL = "email";
const PASSWORD = "password";
const CONFIRM_PASSWORD = "confirm_password";
const PRIVACY = "privacy";
const PERKS = "perks";
const NAME = "name";
const COMPANY_ID = "wescan_company_id";
const CODE = "code";
const TEMPERATURE = "temperature";
const FIRST_NAME = "first_name";
const LAST_NAME = "last_name";

export {
	COMPANY_NAME,
	FULL_NAME,
	MOBILE_NUMBER,
	ADDRESS,
	EMAIL,
	PASSWORD,
	CONFIRM_PASSWORD,
	PRIVACY,
	NAME,
	COMPANY_ID,
	CODE,
	TEMPERATURE,
	FIRST_NAME,
	LAST_NAME,
	PERKS,
};
