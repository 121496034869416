const { gql } = require("@apollo/client");

const GET_APPLICATION_STATUS = gql`
	query get_status($unique_id: String!) {
		workpass_applications(
			where: { unique_id: { _eq: $unique_id } }
			order_by: { id: desc }
		) {
			status
		}
	}
`;

const FOR_APPROVAL = gql`
	query for_approval(
		$offset: Int
		$where: workpass_applications_bool_exp
		$order: order_by
	) {
		workpass_applications(
			where: $where
			offset: $offset
			limit: 5
			order_by: { id: $order }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			mobile_number
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

const APPROVED = gql`
	query for_approval(
		$offset: Int
		$where: workpass_applications_bool_exp
		$order: order_by
	) {
		workpass_applications(
			where: $where
			offset: $offset
			limit: 5
			order_by: { id: $order }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			mobile_number
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

const DENIED = gql`
	query for_approval(
		$offset: Int
		$where: workpass_applications_bool_exp
		$order: order_by
	) {
		workpass_applications(
			where: $where
			offset: $offset
			limit: 5
			order_by: { id: $order }
		) {
			first_name
			last_name
			company_name
			destination_barangay
			destination_location
			destination_municipality
			destination_province
			municipality_code
			mobile_number
			id
			office_number
			origin_barangay
			origin_location
			origin_municipality
			origin_province
			status
			unique_id
			created_at
			workpass_application_files {
				id
				type
				url
			}
		}
		workpass_applications_aggregate(where: $where) {
			aggregate {
				count
			}
		}
	}
`;

const TOTAL = gql`
	query results($municipality_code: String, $is_admin: Boolean) {
		pending: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Pending" }
				is_admin: { _eq: $is_admin }
				_and: [
					{ workpass_application_files: { type: { _eq: "picture" } } }
					{
						workpass_application_files: { type: { _eq: "medical_certificate" } }
					}
					{ workpass_application_files: { type: { _eq: "company_id" } } }
					{
						workpass_application_files: {
							type: { _eq: "certificate_of_employment" }
						}
					}
				]
			}
		) {
			aggregate {
				count
			}
		}
		approved: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Approved" }
				is_admin: { _eq: $is_admin }
			}
		) {
			aggregate {
				count
			}
		}
		denied: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Denied" }
				is_admin: { _eq: $is_admin }
			}
		) {
			aggregate {
				count
			}
		}
	}
`;

const GET_TOTAL = gql`
	query report($municipality_code: String) {
		total: workpass_applications_aggregate(
			where: { municipality_code: { _eq: $municipality_code } }
		) {
			aggregate {
				count
			}
		}
		approved: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Approved" }
			}
		) {
			aggregate {
				count
			}
		}
		pending: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Pending" }
			}
		) {
			aggregate {
				count
			}
		}
		denied: workpass_applications_aggregate(
			where: {
				municipality_code: { _eq: $municipality_code }
				status: { _eq: "Denied" }
			}
		) {
			aggregate {
				count
			}
		}
		info: workpass_applications(
			where: { municipality_code: { _eq: $municipality_code } }
			distinct_on: [municipality_code]
		) {
			origin_municipality
		}
	}
`;

const REPORTS = gql`
	query reports {
		workpass_applications {
			origin_municipality
			municipality_code
			status
		}
	}
`;

const GET_QR = gql`
	query qr_code($unique_id: String) {
		mobile_app_users(where: { unique_installation_id: { _eq: $unique_id } }) {
			id
		}
	}
`;

const LOCATIONS = gql`
	query GetLocations($where: mobile_app_user_locations_bool_exp) {
		mobile_app_user_locations(where: $where) {
			lat
			lng
			app_user_unique_id
			created_at
		}
	}
`;

const COMPANY_INFO = gql`
	query company_info($mobile_number: String) {
		wescan_companies(where: { mobile_number: { _eq: $mobile_number } }) {
			id
			profile_image
			full_name
			company_name
			email
			mobile_number
			is_premium
			wallet_balance
		}
	}
`;

const COMPANY_SCANNERS = gql`
	query scanners($company_id: uuid) {
		wescan_company_scanners(
			where: { wescan_company_id: { _eq: $company_id } }
		) {
			id
			wescan_company_id
			created_at
			code
			name
		}
	}
`;

const GET_VISITOR = gql`
	query visitor($code: String) {
		wescan_users(where: { code: { _eq: $code } }) {
			id
			code
			first_name
			last_name
			mobile_number
			address
			verified
			questionaire_answer_date
		}
	}
`;

const GET_LOGS = gql`
	query visitors($company_id: uuid) {
		wescan_visitors(where: { wescan_company_id: { _eq: $company_id } }) {
			temperature
			created_at
			wescan_user_id
			user {
				first_name
				last_name
				mobile_number
				email
			}
			scanner {
				name
			}
		}
	}
`;

const GET_WORKPASS = gql`
	query workpass($unique_id: String) {
		workpass_applications(where: { unique_id: { _eq: $unique_id } }) {
			first_name
			last_name
			mobile_number
			origin_location
		}
	}
`;

export {
	GET_APPLICATION_STATUS,
	FOR_APPROVAL,
	APPROVED,
	DENIED,
	TOTAL,
	GET_TOTAL,
	REPORTS,
	GET_QR,
	LOCATIONS,
	COMPANY_INFO,
	COMPANY_SCANNERS,
	GET_VISITOR,
	GET_LOGS,
	GET_WORKPASS,
};
